var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"quality"},[_vm._m(0),_c('div',{staticClass:"quality_corevalues"},[_c('moduleTitle',{attrs:{"titleChinese":"核心价值","titleEnglish":"Core Values"}}),_c('coreValues')],1),_c('div',{staticClass:"quality_bg"},[_c('div',{staticClass:"quality_framework"},[_c('moduleTitle',{attrs:{"titleChinese":"系统框架","titleEnglish":"System Framework"}}),_vm._m(1)],1),_c('div',{staticClass:"quality_technology"},[_c('moduleTitle',{attrs:{"titleChinese":"应用技术","titleEnglish":"Applied Technology"}}),_vm._m(2)],1)]),_c('div',{staticClass:"quality_process"},[_c('moduleTitle',{attrs:{"titleChinese":"质控审核流程","titleEnglish":"Quality Control Process"}}),_vm._m(3),_vm._m(4)],1),_c('div',{staticClass:"quality_interactive"},[_c('moduleTitle',{attrs:{"titleChinese":"交互展示","titleEnglish":"Interactive Display"}}),_vm._m(5)],1),_c('div',{staticClass:"quality_support"},[_c('moduleTitle',{attrs:{"titleChinese":"决策支持","titleEnglish":"Decision Support"}}),_vm._m(6)],1),_c('cooperate'),_c('foot')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"quality-banner"},[_c('div',{staticClass:"quality-banner_content"},[_c('h1',[_vm._v("AI智能病历质控")]),_c('p',[_vm._v(" 基于人工智能（AI）的智能病历质控系统，以“智能质控，闭环管理”为建设原则，通过对病历数据后结构化的分析挖掘，运用规范性、完整性、真实性、客观性、准确性和个性化的智能质控引擎对病历进行全面质控，智能识别病历缺陷，实现对临床医师病历书写过程的全流程管理，降低病历缺陷和纠纷隐患的发生率，全面提升医院病历质量和医疗质量的提升，实现病历质控从人工到人工智能的转变。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"quality_framework-img"},[_c('img',{attrs:{"src":require("@/assets/images/systemFramework.png"),"alt":"","width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"quality_technology-img"},[_c('img',{attrs:{"src":require("@/assets/images/appliedTechnology.png"),"alt":"","width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"quality_process-clinician"},[_c('img',{attrs:{"src":require("@/assets/images/clinician.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"quality_process-qualityController"},[_c('img',{attrs:{"src":require("@/assets/images/qualityController.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"quality_interactive-img"},[_c('img',{attrs:{"src":require("@/assets/images/quality.png"),"alt":"","width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"quality_support-img"},[_c('img',{attrs:{"src":require("@/assets/images/support.png"),"alt":"","width":"100%"}})])
}]

export { render, staticRenderFns }