<template>
  <!-- AI智能病历质控 -->
  <div class="quality">
    <div class="quality-banner">
      <div class="quality-banner_content">
        <h1>AI智能病历质控</h1>
        <p>
          基于人工智能（AI）的智能病历质控系统，以“智能质控，闭环管理”为建设原则，通过对病历数据后结构化的分析挖掘，运用规范性、完整性、真实性、客观性、准确性和个性化的智能质控引擎对病历进行全面质控，智能识别病历缺陷，实现对临床医师病历书写过程的全流程管理，降低病历缺陷和纠纷隐患的发生率，全面提升医院病历质量和医疗质量的提升，实现病历质控从人工到人工智能的转变。
        </p>
      </div>
    </div>
    <div class="quality_corevalues">
      <moduleTitle
        titleChinese="核心价值"
        titleEnglish="Core Values"
      ></moduleTitle>
      <coreValues></coreValues>
    </div>
    <div class="quality_bg">
      <div class="quality_framework">
        <moduleTitle
          titleChinese="系统框架"
          titleEnglish="System Framework"
        ></moduleTitle>
        <div class="quality_framework-img">
          <img src="@/assets/images/systemFramework.png" alt="" width="100%" />
        </div>
      </div>
      <div class="quality_technology">
        <moduleTitle
          titleChinese="应用技术"
          titleEnglish="Applied Technology"
        ></moduleTitle>
        <div class="quality_technology-img">
          <img
            src="@/assets/images/appliedTechnology.png"
            alt=""
            width="100%"
          />
        </div>
      </div>
    </div>
    <div class="quality_process">
      <moduleTitle
        titleChinese="质控审核流程"
        titleEnglish="Quality Control Process"
      ></moduleTitle>
      <div class="quality_process-clinician">
        <img src="@/assets/images/clinician.png" alt="" />
      </div>
      <div class="quality_process-qualityController">
        <img src="@/assets/images/qualityController.png" alt="" />
      </div>
    </div>
    <div class="quality_interactive">
      <moduleTitle
        titleChinese="交互展示"
        titleEnglish="Interactive Display"
      ></moduleTitle>
      <div class="quality_interactive-img">
        <img src="@/assets/images/quality.png" alt="" width="100%" />
      </div>
    </div>
    <div class="quality_support">
      <moduleTitle
        titleChinese="决策支持"
        titleEnglish="Decision Support"
      ></moduleTitle>
      <div class="quality_support-img">
        <img src="@/assets/images/support.png" alt="" width="100%" />
      </div>
    </div>
    <cooperate></cooperate>
    <foot></foot>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  components: {
    moduleTitle: () => import("@/components/moduleTitle.vue"),
    coreValues: () => import("@/components/coreValues.vue"),
    foot: () => import("@/components/footer.vue"),
    cooperate: () => import("@/components/cooperate.vue"),
  }
};
</script>

<style lang="scss" scoped>
$content-width: 1200px;
$content-bottom: 90px;
.quality {
  overflow-y: scroll;
  // margin-top: -64px;
  background: url("../../assets/images/AI-bg-1.png") no-repeat;
  background-size: 868px 868px;
  background-position-y: 276px;
  background-position-x: -50px;
  &-banner {
    height: 458px;
    background: url("../../assets/images/AI-banner.png") no-repeat;
    background-size: cover;
    &_content {
      width: 1200px;
      margin: 0 auto;
    }
    h1 {
      font-weight: 500;
      color: #ffffff;
      font-size: 48px;
      padding-top: 108px;
      padding-bottom: 16px;
      margin: 0;
    }
    p {
      width: 503px;
      font-size: 16px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.65);
      margin: 0;
    }
  }
  &_bg {
    background: url("../../assets/images/AI-bg-2.png") no-repeat;
    background-size: 750px 750px;
    background-position: right 272px;
    margin-bottom: $content-bottom;
  }
  &_corevalues {
    width: $content-width;
    margin: 90px auto 66px;
  }
  &_framework {
    margin-bottom: $content-bottom;
    &-img {
      width: $content-width;
      margin: 0 auto;
    }
  }
  &_technology {
    width: $content-width;
    margin: 0 auto;
    margin-bottom: $content-bottom;
    &-img {
      width: 100%;
    }
  }
  &_process {
    margin-bottom: $content-bottom;
    img {
      display: block;
      width: $content-width;
      margin: 0 auto;
      padding-top: 45px;
    }
    &-clinician {
      height: 302px;
      background: linear-gradient(
        90deg,
        rgba(64, 123, 245, 0.05) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
    &-qualityController {
      height: 302px;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(251, 183, 38, 0.05) 100%
      );
    }
  }
  &_interactive {
    width: $content-width;
    margin: 0 auto;
    &-img {
      margin-top: -60px;
      width: 100%;
    }
  }
  &_support {
    height: 829px;
    padding-top: 90px;
    box-sizing: border-box;
    background: linear-gradient(
      180deg,
      #f1f6ff 0%,
      rgba(255, 255, 255, 0) 100%
    );
    margin-bottom: $content-bottom;
    &-img {
      width: 1046px;
      margin: 0 auto;
      margin-top: -70px;
    }
  }
}
</style>